@import "~antd/dist/antd.css";

.App {
  text-align: center;
  overscroll: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
h1 {
  font-size: 25px;
font-weight: 600;
}
h2 {
  font-size: 20px;
  font-weight: 600;
}
h3 {
  font-size: 18px;
  font-weight: 600;
}
h4 {
  font-size: 17px;
  font-weight: 500;
}
h5 {
  font-size: 16px;
  font-weight: 500;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Fix image preview positioning */
img.ant-image-preview-img {
  display: inline-block;
}

/* Align log out to bottom of Sider */
.ant-layout-sider-children {
  position: relative;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
